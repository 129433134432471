
import { computed, defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import ContactsAllContactsTable from '@/components/pages/contacts/allContacts/ContactsAllContactsTable.vue'
import ContactsAllContactsFilter from '@/components/pages/contacts/allContacts/ContactsAllContactsFilter.vue'
import ContactsAllContactsEmpty from '@/components/pages/contacts/allContacts/ContactsAllContactsEmpty.vue'
import ViewSaver from '@/components/shared/viewSaver/ViewSaver.vue'
import { getTableData } from '@/services/tableService'
import type { TableHeaders } from '@/definitions/shared/types'
import { useModes } from '@/compositions/modes'
import type { ContactLine } from '@/definitions/contacts/allContacts/types'
import { contactsFilter } from '@/definitions/shared/filters/filtersList/data'
import { useFilters } from '@/compositions/filters'
import UseContactsSegmentsOpenModal from '@/compositions/contacts/useContactsSegmentsOpenModal'

export default defineComponent({
  components: {
    ContactsAllContactsTable,
    ContactsAllContactsFilter,
    ContactsAllContactsEmpty,
    PageContent,
    ViewSaver,
  },
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    const { isEmptyMode } = useModes()
    const { openSegmentsModal } = UseContactsSegmentsOpenModal()

    const search = ref('')
    const { filters, isDefault, reset } = useFilters(contactsFilter)
    const selected = ref<ContactLine[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Full name', value: 'full-name-slot' },
      { text: 'Phone', value: 'phone-slot', width: '17%' },
      { text: 'Email', value: 'email-slot', width: '18%' },
      { text: 'Organization', value: 'organization-slot', width: '20%' },
      { text: 'Type', value: 'type-slot', width: '14%' },
    ])
    const tableItems = ref<ContactLine[]>(getTableData('contactsAllContacts'))
    const filteredTableItems = computed<ContactLine[]>(() => {
      return tableItems.value.filter((item) => item.person.fullName.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      filters,
      isDefault,
      reset,
      search,
      selected,
      tableHeaders,
      tableItems,
      isEmptyMode,
      filteredTableItems,
      openSegmentsModal,
    }
  },
})
