import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_view_saver = _resolveComponent("view-saver")!
  const _component_contacts_all_contacts_filter = _resolveComponent("contacts-all-contacts-filter")!
  const _component_contacts_all_contacts_table = _resolveComponent("contacts-all-contacts-table")!
  const _component_contacts_all_contacts_empty = _resolveComponent("contacts-all-contacts-empty")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, { title: _ctx.title }, {
    "filter-bar-dropdown": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-dropdown")
    ]),
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "page-content-top-right": _withCtx(() => [
      _renderSlot(_ctx.$slots, "page-content-top-right")
    ]),
    "next-to-title": _withCtx(() => [
      (!_ctx.isDefault.value)
        ? (_openBlock(), _createBlock(_component_view_saver, {
            key: 0,
            class: "ml-4",
            "has-unsaved-changes": !_ctx.isDefault.value,
            "save-as-label": "Save segment",
            onReset: _ctx.reset,
            onSaveClick: _ctx.openSegmentsModal
          }, null, 8, ["has-unsaved-changes", "onReset", "onSaveClick"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (!_ctx.isEmptyMode)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_contacts_all_contacts_filter, {
              columns: _ctx.tableHeaders,
              "onUpdate:columns": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tableHeaders) = $event)),
              search: _ctx.search,
              "onUpdate:search": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
              selected: _ctx.selected,
              filters: _ctx.filters,
              "changed-filters-count": _ctx.isDefault.changesCount,
              "search-placeholder": "Search contacts",
              "onUpdate:radioValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.filters[$event.name].radioValue = $event.value)),
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.filters[$event.name].value = $event.value)),
              onReset: _ctx.reset
            }, null, 8, ["columns", "search", "selected", "filters", "changed-filters-count", "onReset"]),
            _createVNode(_component_contacts_all_contacts_table, {
              modelValue: _ctx.selected,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selected) = $event)),
              headers: _ctx.tableHeaders,
              items: _ctx.filteredTableItems,
              "item-key": "id",
              "items-name": "contacts",
              "show-selection": "",
              search: _ctx.search
            }, null, 8, ["modelValue", "headers", "items", "search"])
          ], 64))
        : (_openBlock(), _createBlock(_component_contacts_all_contacts_empty, { key: 1 }))
    ]),
    _: 3
  }, 8, ["title"]))
}